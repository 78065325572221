import styled from "styled-components";
import { screenWidths } from "../../util/theme";

export const Container = styled.div<{ size: number }>`
	display: flex;
	flex: ${({ size }) => (size ? size : "unset")};
	flex-direction: column;
	background-color: #a9a9a925;
	margin: 0 auto 3rem auto;
	width: 50%;
	border-bottom: 0.5rem solid #dbd7f6;
	padding: 0 1.5rem;

	@media (max-width: ${screenWidths.l}) {
		padding: 0 0.5rem;
		margin: 0;
		width: 100%;
		box-sizing: border-box;
		margin-bottom: 1rem;
	}
`;

export const Title = styled.div`
	font-size: 1.625rem;
	display: flex;
	justify-content: center;
	margin: 1.375rem 0;
	font-family: "Caveat Brush", cursive;
	font-style: italic;
	margin-bottom: 1.5rem;
`;
