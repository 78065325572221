import { Link } from "gatsby";
import React from "react";
import Panel from "../Panel/Panel";
import * as S from "./Rules.styles";
import BusinessDetails from "../../data/business.json";
import useLang from "../../hooks/useLang";

const Rules = () => {
	const [lang, language] = useLang();

	return (
		<S.Container>
			<S.PanelContainer>
				<Panel title={lang.rules.title} size={3}>
					<S.Section>
						<S.SectionTitle>{lang.rules.sections.booking.title}</S.SectionTitle>
						<ul>
							<li>
								{lang.rules.sections.booking.booking[0]}
								<a href={`tel:${BusinessDetails.tel}`}>{BusinessDetails.tel}</a>
								{lang.rules.sections.booking.booking[1]}
								<a href={`mailto:${BusinessDetails.email}`}>
									{BusinessDetails.email}
								</a>
								{lang.rules.sections.booking.booking[2]}
								<Link to={language === "en" ? "/en/booking" : "/booking"}>
									{lang.rules.sections.booking.booking[3]}
								</Link>
								{lang.rules.sections.booking.booking[4]}
							</li>
							<li>{lang.rules.sections.booking.when}</li>
						</ul>
						{lang.rules.sections.booking.contact[0]}
						<Link to={language === "en" ? "/en/contact" : "/contact"}>
							{lang.header.contact}
						</Link>
						{lang.rules.sections.booking.contact[1]}
					</S.Section>
					<S.Section>
						<S.SectionTitle>
							{lang.rules.sections.modifyingAndBeingLate.title}
						</S.SectionTitle>
						<ul>
							{lang.rules.sections.modifyingAndBeingLate.sections.map(
								(content, index) => (
									<li key={index}>{content}</li>
								)
							)}
						</ul>
					</S.Section>
					<S.Section>
						<S.SectionTitle>{lang.rules.sections.minors.title}</S.SectionTitle>
						<ul>
							<li>{lang.rules.sections.minors.adultNeeded}</li>
						</ul>
					</S.Section>
					<S.Section>
						<S.SectionTitle>{lang.rules.sections.hygiene.title}</S.SectionTitle>
						<ul>
							{lang.rules.sections.hygiene.sections.map((content, index) => (
								<li key={index}>{content}</li>
							))}
						</ul>
					</S.Section>
					<S.Section>
						<S.SectionTitle>
							{lang.rules.sections.guarantee.title}
						</S.SectionTitle>
						<ul>
							{lang.rules.sections.guarantee.sections.map((content, index) => (
								<li key={index}>{content}</li>
							))}
						</ul>
					</S.Section>
					<S.Section>
						<S.SectionTitle>{lang.rules.sections.payment.title}</S.SectionTitle>
						<ul>
							{lang.rules.sections.payment.sections.map((content, index) => (
								<li key={index}>{content}</li>
							))}
						</ul>
					</S.Section>
				</Panel>
			</S.PanelContainer>
		</S.Container>
	);
};

export default Rules;
