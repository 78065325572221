import styled from "styled-components";
import { screenWidths } from "../../util/theme";

export const Container = styled.div`
	display: flex;
`;

export const PanelContainer = styled.div`
	display: flex;
	flex: 1;
	gap: 3rem;
`;

export const List = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0 1rem;
	margin: 0 6rem;
`;

export const Section = styled.div`
	margin-bottom: 1rem;
`;

export const SectionTitle = styled.div`
	font-weight: bold;
`;

export const OpenTimes = styled.div`
	margin: 0.5rem 0;
`;
