import React, { ReactNode } from "react";
import * as S from "./Panel.styles";

type Props = {
	children?: ReactNode;
	title?: string;
	size?: number;
};

const Panel = ({ children, title, size }: Props) => {
	return (
		<S.Container size={size ?? 0}>
			<S.Title>{title}</S.Title>
			{children}
		</S.Container>
	);
};

export default Panel;
